import { HttpEvent, HttpHandler, HttpInterceptor, HttpInterceptorFn, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { ConfirmationService } from 'primeng/api';
import { catchError, Observable, throwError } from 'rxjs';
import { ToastService } from '../../services/toast/toast.service';

@Injectable()
export class ErrorsInterceptor implements HttpInterceptor {

  constructor(private router: Router,
    private confirmationService: ConfirmationService,
    private toast: ToastService,

  ) { }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(catchError(err => {
      if (err.status === 401) {

        this.toast.showError("خطأ", err.error.message)
        localStorage.clear();
        this.router.navigate(["auth/login"]);
      }
      else if (err.status === 403) {
        this.confirmationService.confirm({
          message: err.error.message,
          accept: () => {
            this.router.navigate(["auth/login"]);
          },
        });
      }
      else if (err.status === 500) {
        this.toast.showError("خطأ", err.error.message)
      }
      else if (err.status === 400) {
        this.toast.showError("خطأ", err.error.message)
      }
      else if (err.status === 422) {
        this.toast.showError("خطأ", err.error.message || err?.message)
      }
      else {
        this.toast.showError("خطأ", " خطأ غير متوقع قد يكون بسبب اتصال الانترنت")
      }
      let error: any = err.error.message || err.statusText || "خطأ غير متوقع";
      return throwError(error);
    }))
  }
}
