import { auth } from './../../../constent/routes';
import { Component } from '@angular/core';
import { ToastService } from '../../shared/services/toast/toast.service';
import { Router } from '@angular/router';
import { AuthService } from '../service/auth.service';
import { FormBuilder, Validators } from '@angular/forms';
import { passwordREGEX } from '../../../constent/regex';
import { ConfirmationService } from 'primeng/api';
import { AnimationOptions } from 'ngx-lottie';
import { AnimationItem } from 'ngx-lottie/lib/symbols';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrl: './login.component.css'
})
export class LoginComponent {

  loading = false

  constructor(
    private toast: ToastService,
    private route: Router,
    private auth: AuthService,
    private fb: FormBuilder,
    private confirmationService: ConfirmationService,

  ) { }

  loginForm = this.fb.group({
    email: ['', [Validators.required, Validators.email]],
    password: ['', [Validators.required]]
  })

  login() {
    if (this.loginForm.valid) {
      this.loading = true
      this.auth.login(this.loginForm.value).subscribe({
        next: res => {
          localStorage.setItem("token", res.data.token)
          localStorage.setItem("user", JSON.stringify(res.data.user))
          localStorage.setItem("role", JSON.stringify(res.data.user.role))
          this.auth.avatarImg.next(res.data.user.image_url)
          this.loading = false
          this.route.navigate([""]);
          this.toast.showSuccess("تم تسجيل الدخول", `مرحبا ${res.data.user.fullname} نورتنا `)
        },
        error: () => {
          this.loading = false
        },

        complete: () => {
          this.loginForm.reset()
        }
      })
    }
    else {
      this.toast.showError("خطأ", "الرجاء التحقق من البيانات")
    }
  }


  options: AnimationOptions = {
    path: '../../../assets/animate/login.json',
  };

  animationCreated(animationItem: AnimationItem): void {
    console.log(animationItem);
  }


}
