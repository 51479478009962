import { CanActivateFn, Router } from '@angular/router';
import { AuthService } from '../../auth/service/auth.service';
import { inject } from '@angular/core';
import { ToastService } from '../services/toast/toast.service';

export const authGuard: CanActivateFn = () => {

  const auth: AuthService = inject(AuthService)
  const router = inject(Router)
  const toast = inject(ToastService)


  if (!auth.isLoggedIn()) {
    router.navigate(['/auth/login']);
    toast.showError('خطأ', 'يجب تسجيل الدخول اولا')
    return false;
  }
  return true;
};
