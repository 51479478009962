import { Component } from '@angular/core';

@Component({
  selector: 'app-welcome-page',
  templateUrl: './welcome-page.component.html',
  styleUrl: './welcome-page.component.scss'
})
export class WelcomePageComponent {

  images: any;

  responsiveOptions: any[] | undefined;

  constructor() { }

  ngOnInit() {
    this.images = [
      {
        itemImageSrc: '../../../assets/img/avatar-defult.png',
        thumbnailImageSrc: '../../../assets/img/avatar-defult.png',
        alt: 'Description for Image 1',
        title: 'Title 1'
      },
      {
        itemImageSrc: 'https://th.bing.com/th/id/OIP.cEkJMxkFWO9OXJcQfMtfmwHaE6?w=231&h=180&c=7&r=0&o=5&pid=1.7',
        thumbnailImageSrc: 'https://th.bing.com/th/id/OIP.cEkJMxkFWO9OXJcQfMtfmwHaE6?w=231&h=180&c=7&r=0&o=5&pid=1.7',
        alt: 'Description for Image 1',
        title: 'Title 1'
      },
      {
        itemImageSrc: '../../../assets/img/avatar-defult.png',
        thumbnailImageSrc: '../../../assets/img/avatar-defult.png',
        alt: 'Description for Image 1',
        title: 'Title 1'
      },
      {
        itemImageSrc: 'https://th.bing.com/th/id/OIP.cEkJMxkFWO9OXJcQfMtfmwHaE6?w=231&h=180&c=7&r=0&o=5&pid=1.7',
        thumbnailImageSrc: 'https://th.bing.com/th/id/OIP.cEkJMxkFWO9OXJcQfMtfmwHaE6?w=231&h=180&c=7&r=0&o=5&pid=1.7',
        alt: 'Description for Image 1',
        title: 'Title 1'
      },
      {
        itemImageSrc: '../../../assets/img/avatar-defult.png',
        thumbnailImageSrc: '../../../assets/img/avatar-defult.png',
        alt: 'Description for Image 1',
        title: 'Title 1'
      },
      {
        itemImageSrc: 'https://th.bing.com/th/id/OIP.cEkJMxkFWO9OXJcQfMtfmwHaE6?w=231&h=180&c=7&r=0&o=5&pid=1.7',
        thumbnailImageSrc: 'https://th.bing.com/th/id/OIP.cEkJMxkFWO9OXJcQfMtfmwHaE6?w=231&h=180&c=7&r=0&o=5&pid=1.7',
        alt: 'Description for Image 1',
        title: 'Title 1'
      },
      {
        itemImageSrc: '../../../assets/img/avatar-defult.png',
        thumbnailImageSrc: '../../../assets/img/avatar-defult.png',
        alt: 'Description for Image 1',
        title: 'Title 1'
      },
      {
        itemImageSrc: 'https://th.bing.com/th/id/OIP.cEkJMxkFWO9OXJcQfMtfmwHaE6?w=231&h=180&c=7&r=0&o=5&pid=1.7',
        thumbnailImageSrc: 'https://th.bing.com/th/id/OIP.cEkJMxkFWO9OXJcQfMtfmwHaE6?w=231&h=180&c=7&r=0&o=5&pid=1.7',
        alt: 'Description for Image 1',
        title: 'Title 1'
      },
      {
        itemImageSrc: '../../../assets/img/avatar-defult.png',
        thumbnailImageSrc: '../../../assets/img/avatar-defult.png',
        alt: 'Description for Image 1',
        title: 'Title 1'
      },
      {
        itemImageSrc: 'https://th.bing.com/th/id/OIP.cEkJMxkFWO9OXJcQfMtfmwHaE6?w=231&h=180&c=7&r=0&o=5&pid=1.7',
        thumbnailImageSrc: 'https://th.bing.com/th/id/OIP.cEkJMxkFWO9OXJcQfMtfmwHaE6?w=231&h=180&c=7&r=0&o=5&pid=1.7',
        alt: 'Description for Image 1',
        title: 'Title 1'
      },
      {
        itemImageSrc: '../../../assets/img/avatar-defult.png',
        thumbnailImageSrc: '../../../assets/img/avatar-defult.png',
        alt: 'Description for Image 1',
        title: 'Title 1'
      },
      {
        itemImageSrc: 'https://th.bing.com/th/id/OIP.cEkJMxkFWO9OXJcQfMtfmwHaE6?w=231&h=180&c=7&r=0&o=5&pid=1.7',
        thumbnailImageSrc: 'https://th.bing.com/th/id/OIP.cEkJMxkFWO9OXJcQfMtfmwHaE6?w=231&h=180&c=7&r=0&o=5&pid=1.7',
        alt: 'Description for Image 1',
        title: 'Title 1'
      },
      {
        itemImageSrc: '../../../assets/img/avatar-defult.png',
        thumbnailImageSrc: '../../../assets/img/avatar-defult.png',
        alt: 'Description for Image 1',
        title: 'Title 1'
      },
      {
        itemImageSrc: 'https://th.bing.com/th/id/OIP.cEkJMxkFWO9OXJcQfMtfmwHaE6?w=231&h=180&c=7&r=0&o=5&pid=1.7',
        thumbnailImageSrc: 'https://th.bing.com/th/id/OIP.cEkJMxkFWO9OXJcQfMtfmwHaE6?w=231&h=180&c=7&r=0&o=5&pid=1.7',
        alt: 'Description for Image 1',
        title: 'Title 1'
      },
      {
        itemImageSrc: '../../../assets/img/avatar-defult.png',
        thumbnailImageSrc: '../../../assets/img/avatar-defult.png',
        alt: 'Description for Image 1',
        title: 'Title 1'
      },
      {
        itemImageSrc: 'https://th.bing.com/th/id/OIP.cEkJMxkFWO9OXJcQfMtfmwHaE6?w=231&h=180&c=7&r=0&o=5&pid=1.7',
        thumbnailImageSrc: 'https://th.bing.com/th/id/OIP.cEkJMxkFWO9OXJcQfMtfmwHaE6?w=231&h=180&c=7&r=0&o=5&pid=1.7',
        alt: 'Description for Image 1',
        title: 'Title 1'
      },
      {
        itemImageSrc: '../../../assets/img/avatar-defult.png',
        thumbnailImageSrc: '../../../assets/img/avatar-defult.png',
        alt: 'Description for Image 1',
        title: 'Title 1'
      },
      {
        itemImageSrc: 'https://th.bing.com/th/id/OIP.cEkJMxkFWO9OXJcQfMtfmwHaE6?w=231&h=180&c=7&r=0&o=5&pid=1.7',
        thumbnailImageSrc: 'https://th.bing.com/th/id/OIP.cEkJMxkFWO9OXJcQfMtfmwHaE6?w=231&h=180&c=7&r=0&o=5&pid=1.7',
        alt: 'Description for Image 1',
        title: 'Title 1'
      },
      {
        itemImageSrc: '../../../assets/img/avatar-defult.png',
        thumbnailImageSrc: '../../../assets/img/avatar-defult.png',
        alt: 'Description for Image 1',
        title: 'Title 1'
      },
      {
        itemImageSrc: 'https://th.bing.com/th/id/OIP.cEkJMxkFWO9OXJcQfMtfmwHaE6?w=231&h=180&c=7&r=0&o=5&pid=1.7',
        thumbnailImageSrc: 'https://th.bing.com/th/id/OIP.cEkJMxkFWO9OXJcQfMtfmwHaE6?w=231&h=180&c=7&r=0&o=5&pid=1.7',
        alt: 'Description for Image 1',
        title: 'Title 1'
      },
      {
        itemImageSrc: '../../../assets/img/avatar-defult.png',
        thumbnailImageSrc: '../../../assets/img/avatar-defult.png',
        alt: 'Description for Image 1',
        title: 'Title 1'
      },
      {
        itemImageSrc: 'https://th.bing.com/th/id/OIP.cEkJMxkFWO9OXJcQfMtfmwHaE6?w=231&h=180&c=7&r=0&o=5&pid=1.7',
        thumbnailImageSrc: 'https://th.bing.com/th/id/OIP.cEkJMxkFWO9OXJcQfMtfmwHaE6?w=231&h=180&c=7&r=0&o=5&pid=1.7',
        alt: 'Description for Image 1',
        title: 'Title 1'
      },
      {
        itemImageSrc: '../../../assets/img/avatar-defult.png',
        thumbnailImageSrc: '../../../assets/img/avatar-defult.png',
        alt: 'Description for Image 1',
        title: 'Title 1'
      },
      {
        itemImageSrc: 'https://th.bing.com/th/id/OIP.cEkJMxkFWO9OXJcQfMtfmwHaE6?w=231&h=180&c=7&r=0&o=5&pid=1.7',
        thumbnailImageSrc: 'https://th.bing.com/th/id/OIP.cEkJMxkFWO9OXJcQfMtfmwHaE6?w=231&h=180&c=7&r=0&o=5&pid=1.7',
        alt: 'Description for Image 1',
        title: 'Title 1'
      },
      {
        itemImageSrc: '../../../assets/img/avatar-defult.png',
        thumbnailImageSrc: '../../../assets/img/avatar-defult.png',
        alt: 'Description for Image 1',
        title: 'Title 1'
      },
      {
        itemImageSrc: 'https://th.bing.com/th/id/OIP.cEkJMxkFWO9OXJcQfMtfmwHaE6?w=231&h=180&c=7&r=0&o=5&pid=1.7',
        thumbnailImageSrc: 'https://th.bing.com/th/id/OIP.cEkJMxkFWO9OXJcQfMtfmwHaE6?w=231&h=180&c=7&r=0&o=5&pid=1.7',
        alt: 'Description for Image 1',
        title: 'Title 1'
      },
      {
        itemImageSrc: '../../../assets/img/avatar-defult.png',
        thumbnailImageSrc: '../../../assets/img/avatar-defult.png',
        alt: 'Description for Image 1',
        title: 'Title 1'
      },
      {
        itemImageSrc: 'https://th.bing.com/th/id/OIP.cEkJMxkFWO9OXJcQfMtfmwHaE6?w=231&h=180&c=7&r=0&o=5&pid=1.7',
        thumbnailImageSrc: 'https://th.bing.com/th/id/OIP.cEkJMxkFWO9OXJcQfMtfmwHaE6?w=231&h=180&c=7&r=0&o=5&pid=1.7',
        alt: 'Description for Image 1',
        title: 'Title 1'
      },
      {
        itemImageSrc: '../../../assets/img/avatar-defult.png',
        thumbnailImageSrc: '../../../assets/img/avatar-defult.png',
        alt: 'Description for Image 1',
        title: 'Title 1'
      },
      {
        itemImageSrc: 'https://th.bing.com/th/id/OIP.cEkJMxkFWO9OXJcQfMtfmwHaE6?w=231&h=180&c=7&r=0&o=5&pid=1.7',
        thumbnailImageSrc: 'https://th.bing.com/th/id/OIP.cEkJMxkFWO9OXJcQfMtfmwHaE6?w=231&h=180&c=7&r=0&o=5&pid=1.7',
        alt: 'Description for Image 1',
        title: 'Title 1'
      },
      {
        itemImageSrc: '../../../assets/img/avatar-defult.png',
        thumbnailImageSrc: '../../../assets/img/avatar-defult.png',
        alt: 'Description for Image 1',
        title: 'Title 1'
      },
      {
        itemImageSrc: 'https://th.bing.com/th/id/OIP.cEkJMxkFWO9OXJcQfMtfmwHaE6?w=231&h=180&c=7&r=0&o=5&pid=1.7',
        thumbnailImageSrc: 'https://th.bing.com/th/id/OIP.cEkJMxkFWO9OXJcQfMtfmwHaE6?w=231&h=180&c=7&r=0&o=5&pid=1.7',
        alt: 'Description for Image 1',
        title: 'Title 1'
      },
      {
        itemImageSrc: '../../../assets/img/avatar-defult.png',
        thumbnailImageSrc: '../../../assets/img/avatar-defult.png',
        alt: 'Description for Image 1',
        title: 'Title 1'
      },
      {
        itemImageSrc: 'https://th.bing.com/th/id/OIP.cEkJMxkFWO9OXJcQfMtfmwHaE6?w=231&h=180&c=7&r=0&o=5&pid=1.7',
        thumbnailImageSrc: 'https://th.bing.com/th/id/OIP.cEkJMxkFWO9OXJcQfMtfmwHaE6?w=231&h=180&c=7&r=0&o=5&pid=1.7',
        alt: 'Description for Image 1',
        title: 'Title 1'
      },
      {
        itemImageSrc: '../../../assets/img/avatar-defult.png',
        thumbnailImageSrc: '../../../assets/img/avatar-defult.png',
        alt: 'Description for Image 1',
        title: 'Title 1'
      },
      {
        itemImageSrc: 'https://th.bing.com/th/id/OIP.cEkJMxkFWO9OXJcQfMtfmwHaE6?w=231&h=180&c=7&r=0&o=5&pid=1.7',
        thumbnailImageSrc: 'https://th.bing.com/th/id/OIP.cEkJMxkFWO9OXJcQfMtfmwHaE6?w=231&h=180&c=7&r=0&o=5&pid=1.7',
        alt: 'Description for Image 1',
        title: 'Title 1'
      },
    ];
    this.responsiveOptions = [
      {
        breakpoint: '1024px',
        numVisible: 5
      },
      {
        breakpoint: '768px',
        numVisible: 3
      },
      {
        breakpoint: '560px',
        numVisible: 1
      }
    ];
  }
}
