<app-navbar></app-navbar>
<div class="w-screen h-screen bg-black overflow-hidden">

  <video autoplay muted loop id="myVideo">
    <source src="../../../../../assets/vid/hero-bg.mp4" type="video/mp4">
  </video>

  <div class="content mx-auto ">
    <div class="flex flex-col justify-center items-center mt-24 w-[90%] md:w-[50%] ">

      <h1 class="title text-xl font-bold md:text-4xl text-center   ">مرحبا بك في قبيلة ال بازيد</h1>
      <p class="text mt-8 text-center leading-9">تولبه: وكلمة تولبه أتت في معني واحد وهي : كلمة تُولِّه أو تُولَّبَ
        الإنسان أي تحير الإنسان أو الإنسان المتحيروبذلك يكون المعنى هنا والله أعلم أن تولبه أي المحيره وهي فعلا قريه
        تحتار في وضعها بها غيل أي نبع ماء بهِ بساتين لليمون ومزارع للبقول والخضروات وموقعها بسفح الجبل تستقبل الشمال
        والشرق ومن سكانها : آل العمودي والباقروان و البايونس والبازيد والباحيدان والبادعم والباسالمين والباعقيل
        والباجحزر والبامقدم وكما ذكرنا سابقاً نأسف لمن لم ترد أسماء عوا ئلهم هنا.

      </p>
    </div>
  </div>
</div>