<div
  class="w-full h-full md:w-[800px] md:h-[80%] flex justify-center items-center bg-[var(--text-light)]  rounded-lg shadow-xl overflow-hidden z-50">

  <!-- form side  -->
  <div class="w-full min-h-[550px] flex flex-col justify-center relative ">
    <h1 class="text-center">
      تسجيل الدخول

      <!-- info icon -->
      <app-info-icon-flash></app-info-icon-flash>
    </h1>
    <form [formGroup]="loginForm" (ngSubmit)="login()" class="w-full flex flex-col justify-center items-center">

      <div class="w-[90%] flex flex-col justify-center items-center  mb-4">
        <label for="email" class="w-full">
          البريد الالكتروني
        </label>
        <input pInputText type="email" id="email" name="email" placeholder="ادخل البريد الالكتروني" class="w-full mt-2"
          formControlName="email" />
        <small class="w-full text-red-500 mt-1"
          *ngIf="!loginForm.get('email')?.valid && loginForm.get('email')?.touched ">
          البريد الالكتروني مطلوب
        </small>
      </div>

      <div class="w-[90%] flex flex-col justify-center items-center  mb-14">
        <label for="password" class="w-full">
          كلمة المرور
        </label>
        <p-password class="w-full mt-2" [feedback]="false" [toggleMask]="true" formControlName="password" />
        <small class="w-full text-red-500 mt-1"
          *ngIf="loginForm.get('password')?.invalid && loginForm.get('password')?.touched ">
          كلمة المرور يجب ان تحتوي <br>
          <ul>
            <li>من 8 حروف على الاقل</li>
            <li>حرف كبير</li>
            <li>حرف صغير</li>
            <li>رقم</li>
          </ul>
        </small>
      </div>


      <div class="w-[90%] flex flex-col justify-center items-center">
        <button pButton pRipple type="submit" class="p-button-success">

          <span *ngIf="!loading">تسجبل الدخول</span>

          <span *ngIf="loading">جاري التسجبل</span>
          <i *ngIf="loading" class="pi pi-spin pi-spinner mr-2" style="font-size: 14px"></i>

        </button>
        <a routerLink="../signup" class="text-sm mt-2 text-primary">
          ليس لديك حساب؟ انشاء حساب
        </a>
      </div>

    </form>


  </div>

  <!-- disgin side -->
  <div class="w-full hidden md:flex justify-center items-center min-h-[550px]  ">
    <ng-lottie [options]="options" (animationCreated)="animationCreated($event)" />

  </div>

</div>
