import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthComponent } from './auth/auth.component';
import { LoginComponent } from './auth/login/login.component';
import { WelcomePageComponent } from './pages/welcome-page/welcome-page.component';
import { authGuard } from './shared/guards/auth.guard';
import { dashboardGuard } from './shared/guards/dashboard.guard';
import { AlreadyAuthGuard } from './shared/guards/already_auth.guard';
import { SignUpComponent } from './auth/sign-up/sign-up.component';
import { UserProfileComponent } from './pages/user-profile/user-profile.component';
import { HomePageComponent } from './modules/home/components/home-page/home-page.component';

const routes: Routes = [
  {
    path: '',
    component: WelcomePageComponent,
    canActivate: [authGuard]

  },
  {
    path: 'auth',
    component: AuthComponent,
    canActivate: [
      AlreadyAuthGuard
    ],
    children: [
      {
        path: 'login',
        data: {
          title: "تسجيل الدخول",
          description: "تسجيل الدخول للمستخدمين",

        },
        component: LoginComponent
      },
      {
        path: 'signup',
        data: {
          title: "التسجيل",
          description: "انشاء حساب جديد للمستخدمين",

        },
        component: SignUpComponent
      },
    ]
  },
  {
    path: "home",
    data: {
      title: "الرئيسية",
      description: "الرئيسية للمستخدمين",
    },
    loadChildren: () => import('./modules/home/home.module').then(m => m.HomeModule),
    canActivate: [
      authGuard
    ]

  },
  {
    path: "dashboard",
    data: {
      title: "لوحة التحكم",
      description: "لوحة التحكم للمستخدمين",
    },
    canActivate: [
      dashboardGuard,
      authGuard
    ],
    loadChildren: () => import('./modules/dashboard/dashboard.module').then(m => m.DashboardModule)
  },
  {
    path: 'user-profile/:id',
    component: UserProfileComponent,
    canActivate: [authGuard],

  },
  {
    path: "**",
    redirectTo: 'home'
  }

];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
