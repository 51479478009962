import { Component, OnInit } from '@angular/core';
import { UserProfileService } from './user-profile.service';
import { ActivatedRoute } from '@angular/router';
import { FormBuilder, Validators } from '@angular/forms';
import { AuthService } from '../../auth/service/auth.service';
import { passwordREGEX } from '../../../constent/regex';
import { ToastService } from '../../shared/services/toast/toast.service';

@Component({
  selector: 'app-user-profile',
  templateUrl: './user-profile.component.html',
  styleUrl: './user-profile.component.css'
})
export class UserProfileComponent implements OnInit {


  userID: any
  userInfo: any

  visible: boolean = false;
  loading = false

  updateProfileForm: any

  date: Date = new Date();

  selectedFile: File | null = null;
  fileContent: string | ArrayBuffer | null = null;

  // this btn visible when show your profile
  showUpdateBtn: boolean = false

  constructor(
    private userProfileService: UserProfileService,
    private route: ActivatedRoute,
    private fb: FormBuilder,
    private auth: AuthService,
    private toast: ToastService

  ) {

    this.updateProfileForm = this.fb.group({
      email: ['', [Validators.required, Validators.email]],
      phone: [''],
      profession: [''],
      bio: [''],
      image: [''],
      city: [''],
      is_male: [true],
      dob: [''],
      is_phone_private: [true],
      password: ['', Validators.pattern(passwordREGEX)],
      re_password: [''],
      twitter: [''],
      instagram: [''],
      whats_app: [''],

    })
  }


  checkIsYourProfile() {
    let user = this.auth.getUser();
    if (user.id == this.userID) {
      this.showUpdateBtn = true

    } else {
      this.showUpdateBtn = false
    }

  }



  ngOnInit(): void {
    this.route.params.subscribe((params) => {
      this.userID = params['id'];
      this.getProfile(this.userID);

    })

    let id = this.route.snapshot.params['id']
    console.log(id);

  }


  getProfile(id: any) {
    this.checkIsYourProfile()
    this.loading = true
    this.userProfileService.getProfile(id).subscribe({
      next: (res: any) => {
        console.log(res.data);
        this.userInfo = res.data
        this.loading = false
      },
      error: () => {
        this.loading = false
      }
    })
  }



  showDialog() {
    this.onClear()
    this.visible = true;
    this.updateProfileForm = this.fb.group({
      email: [this.userInfo.email || '', [Validators.required, Validators.email]],
      phone: [this.userInfo.phone || ''],
      profession: [this.userInfo.profession || ''],
      bio: [this.userInfo.bio || ''],
      image: [this.userInfo.image_url || ''],
      city: [this.userInfo.city || ''],
      is_male: [this.userInfo.is_male],
      dob: [new Date(this.userInfo.dob) || ''],
      is_phone_private: [this.userInfo.is_phone_private],
      password: [null, Validators.pattern(passwordREGEX)],
      re_password: [null],
      twitter: [this.userInfo?.twitter || ''],
      instagram: [this.userInfo?.instagram || ''],
      whats_app: [this.userInfo?.whats_app || ''],
    })
  }

  //image
  onSelect(event: any) {
    this.selectedFile = event.files[0]; // Get the first selected file
    const reader = new FileReader();

    reader.onload = () => {
      this.fileContent = reader.result; // Save file content in a variable
    };

    if (this.selectedFile) {
      reader.readAsDataURL(this.selectedFile); // Read file content as Data URL
    }
  }

  onClear() {

    this.selectedFile = null;
    this.fileContent = null;
    console.log('File selection cleared');
  }
  onRemove(event: any) {
    this.onClear()
  }

  sumbit() {
    if (this.updateProfileForm.invalid) {
      this.toast.showError('خطأ', 'يرجى التأكد من المدخلات')
      return
    }
    this.updateProfileForm.get('dob').setValue(new Date(this.updateProfileForm.get('dob').value).toISOString())

    const formData = new FormData();
    formData.append('email', this.updateProfileForm.get('email').value);
    formData.append('profession', this.updateProfileForm.get('profession').value ?? '');
    formData.append('bio', this.updateProfileForm.get('bio').value ?? '');
    formData.append('phone', this.updateProfileForm.get('phone').value ?? '');
    formData.append('image', this.selectedFile ?? "");
    formData.append('city', this.updateProfileForm.get('city').value ?? '');
    formData.append('is_male', this.updateProfileForm.get('is_male').value ? JSON.stringify(1) : JSON.stringify(0));
    formData.append('dob', this.updateProfileForm.get('dob').value);
    formData.append('is_phone_private', this.updateProfileForm.get('is_phone_private').value ? JSON.stringify(1) : JSON.stringify(0));
    formData.append('password', this.updateProfileForm.get('password').value ?? '');
    formData.append('twitter', this.updateProfileForm.get('twitter').value ?? '');
    formData.append('instagram', this.updateProfileForm.get('instagram').value ?? '');
    formData.append('whats_app', this.updateProfileForm.get('whats_app').value ?? '');

    this.userProfileService.updateProfile(formData).subscribe({
      next: (res: any) => {
        console.log(res);
        this.toast.showSuccess('تم', 'تم تحديث الملف الشخصي بنجاح')
        localStorage.setItem('user', JSON.stringify(res.data))
        this.auth.avatarImg.next(res.data.image_url)
        this.auth.avatarImg.next(res.data.image_url)
        this.visible = false
        this.getProfile(this.userID)

      }
    })

  }
}
