import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { users } from '../../../constent/routes';

@Injectable({
  providedIn: 'root'
})
export class UserProfileService {

  constructor(private http: HttpClient) { }



  getProfile(id: any) {
    return this.http.get(users.show + id + '/profile');
  }

  updateProfile(data: any) {
    return this.http.post(users.update_profile, data);
  }

}
