<router-outlet></router-outlet>

<!-- toast message -->
<p-toast [breakpoints]="{ '920px': { width: '100%', right: '0', left: '0' } }"
  [showTransformOptions]="'translateY(100%)'" [showTransitionOptions]="'500ms'" [hideTransitionOptions]="'500ms'"
  [showTransformOptions]="'translateX(100%)'" />

<!-- dailog -->
<p-confirmDialog #cd [breakpoints]="{ '1199px': '75vw', '575px': '90vw' }" [style]="{ width: '500px' }">
  <ng-template pTemplate="headless" let-message>
    <div class="flex flex-col items-center p-5 bg-white rounded-md ">
      <div class="border-circle bg-primary inline-flex justify-content-center align-items-center h-6rem w-6rem">
        <i [class]="message.icon + ' text-5xl'"></i>
      </div>
      <span class="font-bold text-2xl block mb-2 mt-4">
        {{ message.header }}
      </span>
      <p class="mb-0 w-full break-words text-center">{{ message.message }}</p>
      <div class="flex align-items-center gap-2 mt-4">
        <button pButton [label]="message.acceptLabel" (click)="cd.accept()" class="w-8rem">
        </button>
        <button pButton [label]="message.rejectLabel" (click)="cd.reject()"
          class="p-button-outlined w-8rem is-showing ">
        </button>
      </div>
    </div>
  </ng-template>
</p-confirmDialog>
