<app-navbar></app-navbar>

<div class="container mx-auto overflow-x-hidden  min-h-screen  p-2 flex justify-center items-center ">


  <!-- card -->
  <div class="w-full  md:w-[70%] h-auto mx-auto bg-[var(--text-light)] rounded-md overflow-hidden shadow-md ">



    <!-- header -->
    <header class="w-full  ">

      <img [src]="userInfo?.image_url" alt="" class="w-[100px] h-[100px] md:w-[150px] md:h-[150px]" loading="lazy">


      <!-- update btn  -->
      <button *ngIf="!loading && showUpdateBtn" (click)="showDialog()" type="button"
        class="bg-[var(--text-light)] p-2 flex justify-center items-center gap-2 rounded-md outline-none text-[var(--icon-color)] font-bold  absolute top-2 left-2 hover:bg-[var(--icon-color)] hover:text-[var(--text-light)] transition-all duration-300 cursor-pointer"
        style="border: 2px solid var(--icon-color);">

        تعديل الملف الشخصي
      </button>

    </header>


    <!-- body -->

    <div *ngIf="loading" class="w-full min-h-[400px] flex justify-center items-center">
      <app-loader></app-loader>
    </div>


    <div *ngIf="!loading" class="w-full min-h-[400px] flex flex-col justify-between  p-4 mt-4 md:mt-10">

      <!-- name & bio  -->
      <div class="w-full text-center">
        <!-- name -->
        <h1 class="text-2xl font-bold">
          {{userInfo?.fullname}}
        </h1>



        <!-- email -->
        <span class="text-[14px] text-gray-600 font-bold mb-0">
          {{userInfo?.email}}
        </span>

        <span class="text-[12px] text-gray-600 font-bold block">( {{userInfo?.is_male ? 'ذكر' : 'انثى'}} )</span>


        <!-- socail media links -->
        <div class="flex gap-4 justify-center items-center mt-2">

          <!-- twitter -->
          <a [href]="userInfo?.twitter" target="_blank" *ngIf="userInfo?.twitter">
            <i class="pi pi-twitter text-black text-xl cursor-pointer hover:-translate-y-1 transition-all duration-500">
            </i>
          </a>

          <!-- instagram -->
          <a [href]="userInfo?.instagram" target="_blank" *ngIf="userInfo?.instagram">
            <i
              class="pi pi-instagram text-amber-500 text-xl cursor-pointer hover:-translate-y-1 transition-all duration-500">
            </i>
          </a>

          <!-- whatsapp -->
          <a [href]="userInfo?.whats_app_url" target="_blank" *ngIf="userInfo?.whats_app">
            <i
              class="pi pi-whatsapp text-green-500 text-xl cursor-pointer hover:-translate-y-1 transition-all duration-500">
            </i>
          </a>

        </div>


        <!-- bio -->
        <p class="text-[14px] text-[var(--text-color)] w-full md:w-2/3 mx-auto" [innerText]="userInfo?.bio">

          <!-- {{userInfo?.bio}} -->

        </p>
      </div>


      <!-- user info  -->
      <div class="h-full w-full  mt-10 flex justify-center gap-20 flex-wrap ">


        <!-- رقم الجوال -->
        <div *ngIf="!userInfo?.is_phone_private && userInfo?.phone" class="flex flex-col justify-center items-center">
          <label for="email" class="text-md font-semibold">
            رقم الجوال
          </label>
          <p class="text-[14px] text-gray-600 font-bold">
            {{userInfo?.phone}}
          </p>
        </div>

        <!-- المدينة -->
        <div *ngIf="userInfo?.city" class="flex flex-col justify-center items-center">
          <label for="email" class="text-md font-semibold">
            المدينة
          </label>
          <p class="text-[14px] text-gray-600 font-bold">
            {{userInfo?.city}}
          </p>
        </div>


        <!-- المهنة -->
        <div *ngIf="userInfo?.profession" class="flex flex-col justify-center items-center">
          <label for="email" class="text-md font-semibold">
            المهنة </label>
          <p class="text-[14px] text-gray-600 font-bold">
            {{userInfo?.profession}}
          </p>
        </div>




      </div>
    </div>

  </div>


</div>

<app-footer></app-footer>


<p-dialog header="تعديل البيانات الشخصية" [modal]="true" [(visible)]="visible"
  [breakpoints]="{ '1199px': '75vw', '575px': '90vw' }" [style]="{ width: '80vw' }">

  <form [formGroup]="updateProfileForm" (ngSubmit)="sumbit()" autocomplete="off">

    <!-- first row  -->
    <div class="flex flex-col lg:flex-row  gap-3  flex-wrap w-full justify-center lg:justify-between p-2 my-10">


      <!-- email  -->
      <div class="flex flex-col gap-4  mb-4 w-full xl:w-[300px]">
        <label for="email" class="text-md font-semibold">
          البريد الالكتروني
        </label>
        <input type="email" id="email" formControlName="email" class="p-inputtext p-component"
          placeholder="ادخل البريد الالكتروني" />
        <small class="text-red-500"
          *ngIf="updateProfileForm.get('email')?.invalid && updateProfileForm.get('email')?.touched">
          البريد الالكتروني مطلوب
        </small>
      </div>


      <!-- المدينة  -->
      <div class="flex flex-col gap-4  mb-4 w-full xl:w-[300px]">
        <label for="city" class="text-md font-semibold">
          المدينة
        </label>
        <input type="text" id="city" formControlName="city" class="p-inputtext p-component"
          placeholder="ادخل المدينة" />
      </div>



      <!-- password  -->
      <div class="flex flex-col gap-4  mb-4 w-full xl:w-[300px]">
        <label for="password" class="text-md font-semibold">
          كلمة المرور
        </label>
        <p-password [feedback]="false" [toggleMask]="true" formControlName="password" autocomplete="new-password"
          placeholder="ادخل كلمة المرور اذا اردت تغييرها" />
        <small class="text-red-500"
          *ngIf="updateProfileForm.controls['password'].invalid && updateProfileForm.get('password')?.touched">
          كلمة المرور يجب ان تحتوي <br>
          <ul>
            <li>من 8 حروف على الاقل</li>
            <li>حرف كبير</li>
            <li>حرف صغير</li>
            <li>رقم</li>
          </ul>
        </small>
      </div>


      <!-- re-password  -->
      <div class="flex flex-col gap-4  mb-4 w-full xl:w-[300px]">
        <label for="re-password" class="text-md font-semibold">
          تأكيد كلمة المرور
        </label>
        <p-password [feedback]="false" [toggleMask]="true" formControlName="re_password" autocomplete="off" />
        <small class="text-red-500"
          *ngIf="updateProfileForm.get('re_password')?.value !== updateProfileForm.get('password')?.value && updateProfileForm.get('re_password')?.touched">
          كلمة المرور غير متطابقة
        </small>
      </div>



    </div>
    <hr style="border-top: 1px solid rgba(0, 0, 0, 0.1);">


    <!-- second row  -->

    <div class="flex flex-col lg:flex-row gap-2 flex-wrap w-full justify-center lg:justify-between p-2 my-10">


      <!-- phone  -->
      <div class="flex flex-col gap-4  mb-4 w-full xl:w-[300px]">
        <label for="phone" class="text-md font-semibold">
          رقم الجوال
        </label>
        <input type="text" id="phone" formControlName="phone" class="p-inputtext p-component"
          placeholder="ادخل رقم الجوال" />
      </div>


      <!-- phone number active  -->
      <div class="flex flex-col gap-4  mb-4 w-full xl:w-[300px]">
        <label for="phone_active" class="text-md font-semibold">
          اظهار رقم الجوال لكافة المستخدمين
        </label>
        <div class="w-full md:w-[48%] flex gap-2 mt-2">
          <div class="flex ">
            <label for="show" class="ml-2">
              اظهار
            </label>
            <p-radioButton name="is_phone_private" [value]='false' inputId="show" formControlName="is_phone_private" />
          </div>
          <div class="flex ">
            <label for="hide" class="ml-2">
              اخفاء
            </label>
            <p-radioButton name="is_phone_private" [value]='true' inputId="hide" formControlName="is_phone_private" />
          </div>
        </div>
      </div>

      <!-- المهنة  -->
      <div class="flex flex-col gap-4  mb-4 w-full xl:w-[300px]">
        <label for="profession" class="text-md font-semibold">
          المهنة
        </label>
        <input type="text" id="profession" formControlName="profession" class="p-inputtext p-component"
          placeholder="ادخل المهنة" />
      </div>


      <!-- date  -->
      <div class="flex flex-col gap-4  mb-4 w-full xl:w-[300px]">
        <label for="profession" class="text-md font-semibold">
          تاريخ الميلاد
        </label>
        <p-calendar formControlName="dob" [showIcon]="true" [showOnFocus]="false" inputId="buttondisplay"
          dateFormat="dd-mm-yy" />

        <!-- <input type="date" id="dob" formControlName="dob" class="p-inputtext p-component" > -->

      </div>





    </div>
    <hr style="border-top: 1px solid rgba(0, 0, 0, 0.1);">


    <!-- third row  -->
    <div class="flex flex-col lg:flex-row  gap-3  flex-wrap w-full justify-center lg:justify-between p-2 my-10">

      <!-- twitter acount  -->
      <div class="flex flex-col gap-4  mb-4 w-full xl:w-[300px]">
        <label for="twitter" class="text-md font-semibold ">
          حساب اكس (تويتر)
          <i class="pi pi-twitter text-black mr-1"></i>
        </label>
        <input type="text" id="twitter" formControlName="twitter" class="p-inputtext p-component"
          placeholder="ادخل رابط ملفك الشخصي" />
      </div>


      <!-- instagram acount  -->
      <div class="flex flex-col gap-4  mb-4 w-full xl:w-[300px]">
        <label for="instagram" class="text-md font-semibold">
          حساب الانستقرام
          <i class="pi pi-instagram text-amber-500 mr-1"></i>
        </label>
        <input type="text" id="instagram" formControlName="instagram" class="p-inputtext p-component"
          placeholder="ادخل رابط ملفك الشخصي" />
      </div>


      <!-- whatsapp acount  -->
      <div class="flex flex-col gap-4  mb-4 w-full xl:w-[300px]">
        <label for="whats_app" class="text-md font-semibold">
          رقم الواتس اب
          <i class="pi pi-whatsapp text-green-500 mr-1"></i>
        </label>
        <input type="text" id="whats_app" formControlName="whats_app" class="p-inputtext p-component"
          placeholder="ادخل رقمك الخاص بالواتس اب" />
      </div>


    </div>
    <hr style="border-top: 1px solid rgba(0, 0, 0, 0.1);">



    <!-- fourth row  -->
    <div class="flex flex-col md:flex-row gap-2 w-full justify-center p-2 my-10">

      <!-- image  -->

      <div class="flex flex-col gap-4 md:w-[calc(100%/2)] mb-2">
        <label for="address" class="text-md font-semibold">
          تغيير الصورة الشخصية
        </label>
        <p-fileUpload name="demo[]" customUpload="true" [multiple]="false" chooseLabel="اختر صورة" cancelLabel="الغاء"
          accept="image/*" maxFileSize="1000000" [showUploadButton]="false" [showCancelButton]="false"
          chooseIcon="pi pi-image ml-2" (onSelect)="onSelect($event)" (onClear)="onClear() "
          (onRemove)="onRemove($event)">

          <ng-template pTemplate="content">
            <div class="flex justify-center items-center flex-col">

              <img [src]="userInfo?.image_url" width="100" class="rounded-full" />
              <span>الصورة الحالية</span>
            </div>
          </ng-template>



        </p-fileUpload>
      </div>



      <!-- bio  -->
      <div class="flex flex-col gap-4 md:w-[calc(100%/2)] mb-2">
        <label for="city" class="text-md font-semibold">
          نبذة تعريفية (Bio)
        </label>
        <textarea name="" id="" rows="9" placeholder="ادخل نبذة تعريفية" formControlName="bio"
          class="p-inputtext p-component"></textarea>

      </div>
    </div>
    <hr style="border-top: 1px solid rgba(0, 0, 0, 0.1);">


    <footer class="w-full flex justify-center items-end  p-1 rounded-t-md mt-4">
      <button type="submit"
        class="bg-[var(--icon-color)] p-2 rounded-md outline-none border-none flex justify-center items-center text-white font-bold cursor-pointer hover:-translate-y-1 transition-all duration-500 ">
        <i class="pi pi-save ml-2"></i>
        حفظ التغييرات
      </button>
    </footer>
  </form>

</p-dialog>
