<div
  class="w-full h-full xl:w-[1000px] xl:h-[90%] flex justify-center items-center bg-[var(--text-light)]  rounded-lg shadow-xl overflow-hidden z-50">

  <!-- form side  -->
  <div class="w-full h-auto lg:min-h-[630px] py-2 lg:py-0 flex flex-col justify-center  ">
    <h1 class="text-center">انشاء حساب

      <!-- info icon -->
      <app-info-icon-flash></app-info-icon-flash>
    </h1>
    <form [formGroup]="SignUpForm" (ngSubmit)="signup()" class="w-full flex flex-col justify-center items-center">


      <div class="w-[90%]  flex flex-col lg:flex-row justify-center  gap-2 mb-2">

        <div class="w-[100%] flex flex-col">
          <label for="fullname" class="w-full">
            الاسم الكامل
          </label>
          <input pInputText type="text" id="fullname" name="fullname" placeholder="ادخل الاسم الكامل"
            class="w-full my-2" formControlName="fullname" />
          <small class="w-full text-red-500 "
            *ngIf="!SignUpForm.get('fullname')?.valid && SignUpForm.get('fullname')?.touched ">
            الاسم الكامل مطلوب
          </small>
        </div>

        <div class="w-[100%] flex flex-col  ">
          <label for="email" class="w-full">
            البريد الالكتروني
          </label>
          <input pInputText type="email" id="email" name="email" placeholder="ادخل البريد الالكتروني"
            class="w-full my-2" formControlName="email" />
          <small class="w-full text-red-500 "
            *ngIf="!SignUpForm.get('email')?.valid && SignUpForm.get('email')?.touched ">
            البريد الالكتروني مطلوب
          </small>
        </div>
      </div>


      <div class="w-[90%]  flex flex-col lg:flex-row justify-center  gap-2 mb-2">

        <div class="w-[100%] flex flex-col">
          <label for="phone" class="w-full">
            رقم الجوال
          </label>
          <input pInputText type="text" id="phone" name="phone" placeholder="ادخل رقم الجوال" class="w-full my-2"
            formControlName="phone" />

        </div>

        <div class="w-[100%] flex flex-col  ">
          <label for="gander" class="text-md font-semibold ">الجنس</label>
          <div class="w-full md:w-[48%] flex gap-2 mt-2">
            <div class="flex ">
              <label for="male" class="ml-2">
                ذكر
              </label>
              <p-radioButton name="is_male" [value]='true' inputId="male" formControlName="is_male" />
            </div>
            <div class="flex ">
              <label for="female" class="ml-2">
                انثى
              </label>
              <p-radioButton name="is_male" [value]='false' inputId="female" formControlName="is_male" />
            </div>
          </div>

        </div>
      </div>


      <div class="w-[90%]  flex flex-col lg:flex-row justify-center  gap-2 mb-2">

        <div class="w-[100%] flex flex-col">
          <label for="password" class="w-full">
            كلمة المرور
          </label>
          <p-password class="w-full mt-2" [feedback]="false" [toggleMask]="true" formControlName="password" />
          <small class="w-full text-red-500 mt-1"
            *ngIf="SignUpForm.get('password')?.invalid && SignUpForm.get('password')?.touched ">
            كلمة المرور يجب ان تحتوي <br>
            <ul>
              <li>من 8 حروف على الاقل</li>
              <li>حرف كبير</li>
              <li>حرف صغير</li>
              <li>رقم</li>
            </ul>
          </small>
        </div>


        <div class="w-[100%] flex flex-col">
          <label for="re_password" class="w-full">
            تأكيد كلمة المرور
          </label>
          <p-password class="w-full mt-2" [feedback]="false" [toggleMask]="true" formControlName="re_password" />
          <small class="w-full text-red-500 mt-1"
            *ngIf="SignUpForm.get('password')?.value != SignUpForm.get('re_password')?.value && SignUpForm.get('re_password')?.touched">
            كلمة المرور غير متطابقة
          </small>
        </div>


      </div>


      <div class="w-[90%] flex flex-col justify-center items-center mt-6">
        <button pButton pRipple type="submit" label="انشاء حساب" class="p-button-success">
        </button>
        <a routerLink="../login" class="text-sm mt-2 text-primary">
          لديك حساب؟ تسجبل الدخول
        </a>
      </div>

    </form>
  </div>

  <!-- disgin side -->
  <div class="w-full hidden md:flex justify-center items-center min-h-[550px]  ">
    <ng-lottie [options]="options" (animationCreated)="animationCreated($event)" />

  </div>

</div>
