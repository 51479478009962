import { CanActivateFn, Router } from '@angular/router';
import { AuthService } from '../../auth/service/auth.service';
import { inject } from '@angular/core';
import { ToastService } from '../services/toast/toast.service';

export const AlreadyAuthGuard: CanActivateFn = (route, state) => {
  const auth: AuthService = inject(AuthService)
  const router = inject(Router)
  const toast = inject(ToastService)


  if (auth.isLoggedIn()) {
    router.navigate(['/home']);
    toast.showInfo('', 'انت مسجل الدخول بالفعل')
    return false;
  }
  return true;
};
