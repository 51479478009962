import { NgModule } from '@angular/core';
import { BrowserModule, Title } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { AuthComponent } from './auth/auth.component';
import { LoginComponent } from './auth/login/login.component';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ConfirmationService, MessageService } from 'primeng/api';
import { ButtonModule } from 'primeng/button';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RippleModule } from 'primeng/ripple';
import { ToastModule } from 'primeng/toast';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { ProgressSpinnerModule } from 'primeng/progressspinner';
import { ToastService } from './shared/services/toast/toast.service';
import { WelcomePageComponent } from './pages/welcome-page/welcome-page.component';
import { tokenInterceptor } from './shared/interceptors/token/token.interceptor';
import { ErrorsInterceptor } from './shared/interceptors/error/error.interceptor';
import { TableModule } from 'primeng/table';
import { GalleriaModule } from 'primeng/galleria';
import { PasswordModule } from 'primeng/password';
import { InputTextModule } from 'primeng/inputtext';
import { SignUpComponent } from './auth/sign-up/sign-up.component';
import { RadioButtonModule } from 'primeng/radiobutton';
import { UserProfileComponent } from './pages/user-profile/user-profile.component';
import { SharedModule } from './shared/shared.module';
import { DialogModule } from 'primeng/dialog';
import { FileUploadModule } from 'primeng/fileupload';
import { CalendarModule } from 'primeng/calendar';
import { LottieComponent, provideLottieOptions } from 'ngx-lottie';
import player from 'lottie-web';
@NgModule({
  declarations: [
    AppComponent,
    AuthComponent,
    LoginComponent,
    WelcomePageComponent,
    SignUpComponent,
    UserProfileComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    ButtonModule,
    BrowserAnimationsModule,
    RippleModule,
    ToastModule,
    SharedModule,
    ConfirmDialogModule,
    ProgressSpinnerModule,
    HttpClientModule,
    FormsModule,
    ToastModule,
    ReactiveFormsModule,
    TableModule,
    GalleriaModule,
    PasswordModule,
    InputTextModule,
    RadioButtonModule,
    SharedModule,
    DialogModule,
    FileUploadModule,
    CalendarModule,
    LottieComponent


  ],
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: tokenInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: ErrorsInterceptor, multi: true },
    provideLottieOptions({
      player: () => player,
    }),
    MessageService,
    ConfirmationService,
    Title
  ],
  bootstrap: [AppComponent],

})
export class AppModule { }
