import { Component } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { ToastService } from '../../shared/services/toast/toast.service';
import { AuthService } from '../service/auth.service';
import { ConfirmationService } from 'primeng/api';
import { passwordREGEX } from '../../../constent/regex';
import { AnimationOptions } from 'ngx-lottie';
import { AnimationItem } from 'ngx-lottie/lib/symbols';
@Component({
  selector: 'app-sign-up',
  templateUrl: './sign-up.component.html',
  styleUrl: './sign-up.component.css'
})
export class SignUpComponent {
  constructor(
    private toast: ToastService,
    private route: Router,
    private auth: AuthService,
    private fb: FormBuilder,
    private ConfirmationService: ConfirmationService

  ) { }

  SignUpForm = this.fb.group({
    fullname: ['', Validators.required],
    email: ['', [Validators.required, Validators.email]],
    phone: [''],
    is_male: [true],
    password: ['', [Validators.required, Validators.pattern(passwordREGEX)]],
    re_password: ['', Validators.required]
  })

  signup() {
    if (this.SignUpForm.valid) {
      this.auth.signup(this.SignUpForm.value).subscribe({
        next: (res: any) => {
          console.log(res)
          this.ConfirmationService.confirm({
            message: res.message + " " + res.data.admin_name + " " + res.data.admin_phone,
            header: 'تنبيه',
            icon: 'pi pi-exclamation-triangle text-red-500',
            acceptLabel: 'اغلاق',
            accept: () => {
              this.ConfirmationService.close();
            },
          })
        },
        complete: () => {
          this.SignUpForm.reset()
        }
      })


    }
  }



  options: AnimationOptions = {
    path: '../../../assets/animate/login.json',
  };

  animationCreated(animationItem: AnimationItem): void {
    console.log(animationItem);
  }


}
