import { inject } from '@angular/core';
import { CanActivateFn, Router } from '@angular/router';
import { AuthService } from '../../auth/service/auth.service';
import { ToastService } from '../services/toast/toast.service';

export const dashboardGuard: CanActivateFn = (route, state) => {
  const role: AuthService = inject(AuthService)
  const router = inject(Router)
  const toast = inject(ToastService)
  const User_type = role.getRole().name

  if (User_type === 'user') {
    router.navigate(["home"]);
    toast.showError('خطأ', 'لا يمكنك دخول هذه الصفحة', 5000)

  }
  return true;
};
